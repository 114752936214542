import React from "react"
import Slider from "react-slick"
import TrailerCarouselSlide from "./trailercarouselslide"

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const FeaturedCarousel = props => {

  return (
    <div className="">
      <div
        className={`fixed-carousel fixed ms:hidden mt-0 z-20 top-0 bottom-0 right-0 left-0 flex items-center bg-black bg-opacity-80 ${
          props.trailerCarousel
            ? console.log(`trailerCarousel ${props.trailerCarousel}`)
            : "hidden"
        }`}
      >
        <Slider
          {...settings}
          className={"arrows-bottom w-full"}
          asNavFor={props.nav1}
          ref={slider => (props.slider2.current = slider)}
        >
          {props.posterData.map(datum =>
            TrailerCarouselSlide(
              datum,
              props.closeTrailerCarousel,
              props.trailerCarousel
            )
          )}
        </Slider>
      </div>
    </div>
  )
}

export default FeaturedCarousel
