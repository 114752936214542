import React from "react"
import BackgroundImage from "gatsby-background-image"
import FeaturedCarousel from "./featuredcarousel"
import CategoryCarousel from "./categoryCarousel"

const Filmography = props => {
  const data = props.data

  const sources = [
    data.filmographyBackgroundImageMobile.fluid,
    {
      ...data.filmographyBackgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  const categoryList =
    data.categoryCarousels.categoryList

  return (
    <BackgroundImage fluid={sources} className="">
      <div className="trailers mx-auto space-y-10 lg:space-y-16 relative -top-28 lg:-top-36 overflow-x-hidden">
        <FeaturedCarousel
          data={data}            
          showTrailerCarousel={props.showTrailerCarousel}
          closeTrailerCarousel={props.closeTrailerCarousel}
          nav2={props.nav2}
          slider1={props.slider1}
        />
        {categoryList.map(category => (
          <CategoryCarousel
            key={category.title}
            data={data}
            categoryName={category.title}
            posterData={category.items}
          />
        ))}
      </div>
    </BackgroundImage>
  )
}

export default Filmography
