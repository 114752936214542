import React from "react"
import Img from "gatsby-image"

export default props => {
  return (
    <div className="slide">
      <div className="category-poster">
        <a target="_blank" rel="noopener noreferrer" href={props.externalLink}>
          <Img fluid={props.poster.fluid} className="cursor-pointer category-slide-image" />
        </a>
        <div>
          <span className="body-3">{props.title}</span>
        </div>
      </div>
    </div>
  )
}
