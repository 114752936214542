import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FilmographyHero from "../components/filmographyhero"
import Filmography from "../components/filmography"
import Newsroom from "../components/newsroom"
import FixedOverlay from "../components/fixedOverlay"

const FilmsPage = ({ data }) => {
  const filmographyData = data.contentfulFilmographyPage

  const [trailerCarousel, setTrailerCarousel] = useState(false)

  const showTrailerCarousel = () => {
    setTrailerCarousel(true)
  }

  const closeTrailerCarousel = () => {
    setTrailerCarousel(false)
  }

  const [sliders, setSliders] = useState({ nav1: null, nav2: null })
  let slider1 = useRef()
  let slider2 = useRef()

  useEffect(() => {
    setSliders({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = sliders

  const featuredPosters =
    filmographyData.featuredTrailers.items

  return (
    <>
      <Layout>
        <SEO title="Filmography" />
        <FilmographyHero data={filmographyData}/>
        <Filmography
          data={filmographyData}
          trailerCarousel={trailerCarousel}
          showTrailerCarousel={showTrailerCarousel}
          closeTrailerCarousel={closeTrailerCarousel}
          nav2={nav2}
          slider1={slider1}
        />
        <Newsroom />
      </Layout>
      <FixedOverlay
        data={filmographyData}
        posterData={featuredPosters}
        trailerCarousel={trailerCarousel}
        showTrailerCarousel={showTrailerCarousel}
        closeTrailerCarousel={closeTrailerCarousel}
        nav1={nav1}
        slider2={slider2}
      />
    </>
  )
}

export const query = graphql`
  query posterData {
    contentfulFilmographyPage {
      heroHeading
      heroBackgroundImageDesktop {
        fluid(maxWidth:1920, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroBackgroundImageMobile {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featuredTrailers{
        title
        items {
          title
          trailer {
            file {
              url
            }
          }
          description
          externalLink
          poster {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          trailerThumbnail {
            fluid {
              srcWebp
            }
          }
          releaseYear
        }
      }
      categoryCarousels{
        categoryList {
          title
          items {
            poster {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            title
            externalLink
          }
        }
      }
      filmographyBackgroundImageDesktop {
        fluid(maxWidth:1920, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      filmographyBackgroundImageMobile {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default FilmsPage
