import React from "react"
import FeaturedPost from "./featuredpost"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Newsroom = () => {
  const newsroomData = useStaticQuery(graphql`
    query {
      contentfulFeaturedNewsSection {
        featuredNews {
          articleImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          articleLink
          category
          headline
          date
        }
        eyebrow
        heading
        buttonText
        backgroundImageDesktop {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        backgroundImageMobile {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const data = newsroomData.contentfulFeaturedNewsSection

  const sources = [
    data.backgroundImageMobile.fluid,
    {
      ...data.backgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <div className="newsroom container mx-auto t-grid flex lg:items-center py-10 pb-16 lg:py-0 space-y-8">
        <div className="col-span-full lg:col-start-2 lg:col-span-6 lg:space-y-12">
          <div className="space-y-2 max-h-min">
            <span className="eyebrow">{data.eyebrow}</span>
            <h2 className="max-w-md">{data.heading}</h2>
          </div>

          <Link to="/newsroom">
            <button className="button-white cta mt-16 hidden lg:block">
              {data.buttonText}
            </button>
          </Link>
        </div>
        <div className="col-span-full lg:col-start-8 lg:col-span-4 space-y-10">
          <FeaturedPost
            data={data.featuredNews}
          />
          <Link to="/newsroom">
            <button className="button-white cta mt-8 lg:hidden">
              {data.buttonText}
            </button>
          </Link>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Newsroom
