import React from "react"
import Img from "gatsby-image"
import LinkOutArrow from "../images/icons/linkOutArrowWhite.svg"

const FeaturedPost = props => {
  const data = props.data
  return (
    <div className={`${props.class} image-card lg:max-w-sm`}>
      <Img fluid={data.articleImage.fluid} />
      <div className="p-5 h-1/2">
        <span className="eyebrow">{data.category}</span>
        <div className="space-y-4">
          <h4>{data.headline}</h4>
          <div>
            <span className="body-3">{data.date}</span>
          </div>
          <div className="w-full flex justify-end">
            <a href={data.articleLink}>
              <img src={LinkOutArrow} alt="link-out-arrow" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedPost
