import React from "react"
import Slider from "react-slick"
import LeftTitle from "./lefttitle.js"
import FeaturedCarouselSlide from "./featuredcarouselslide"

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}


const FeaturedCarousel = props => {
  const data = props.data.featuredTrailers
  return (
    <>
      <div className="space-y-8">
        <div className="container mx-auto">
          <LeftTitle text={data.title} />
        </div>
        <div className="featured-slider mx-auto">
          <Slider
            {...settings}
            asNavFor={props.nav2}
            ref={slider => (props.slider1.current = slider)}
          >
            {data.items.map(datum =>
              FeaturedCarouselSlide(
                datum,
                props.showTrailerCarousel,
                props.closeTrailerCarousel
              )
            )}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default FeaturedCarousel
