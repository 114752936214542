import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import ReactPlayer from "react-player"
import CloseIcon from "../images/icons/closeIconSmall.svg"
import PlayButton from "../images/icons/playButton.svg"

const FeaturedCarouselSlide = (props, showTrailerCarousel, closeTrailerCarousel) => {
  const [toPlay, setToPlay] = useState(false)
  const [showTrailer, setShowTrailer] = useState(false)
  const [isSwiping, setSwiping] = useState(false)
  const [isClicking, setClicking] = useState(false)
  const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 })
  const [mouseEnd, setMouseEnd] = useState({ x: 0, y: 0 })

  const togglePlay = () => {
    if (!isSwiping) {
      if (!toPlay) {
        if (windowWidth > 474){
          setToPlay(true)
          showTrailerCarousel()
        } else {
          showTrailerCarousel()
        }
      } else {
        setToPlay(false)
      }
    }
  }

  const openTrailer = () => {
    if (!isSwiping) {
      if (windowWidth < 474) {
        showTrailerCarousel()
      } else {
        setShowTrailer(true)
      }
    }
  }

  const closeTrailer = () => {
    setToPlay(false)
    setShowTrailer(false)
    closeTrailerCarousel()
  }

  useEffect(() => {
    if (mouseEnd.x - mouseStart.x > 20 || mouseEnd.x - mouseStart.x < -20) {
      setSwiping(true)
    }
  }, mouseEnd)

  const [windowWidth, setWindowWidth] = useState(undefined)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth)
      if (windowWidth < 474) {
        setToPlay(false)
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }) // Empty array ensures that effect is only run on mount

  return (
    <div
      onMouseEnter={() => {
        if (windowWidth > 474) {
          openTrailer()
        }
      }}
      onMouseLeave={() => {
        if (windowWidth > 474) {
          closeTrailer()
        }
      }}
      className="featured-slide pl-4 ms:pl-0 ms:pr-4"
    >
      <div
        className={
          "trailer z-10 " +
          (showTrailer
            ? "relative"
            : " trailer-closed absolute invisible opacity-0")
        }
      >
        <div
          className="h-full bg-black relative pb-14"
          onMouseDown={e => {
            setSwiping(false)
            setClicking(true)
            setMouseStart({ x: e.pageX, y: e.pageY })
          }}
          onMouseMove={e => {
            if (isClicking) {
              setMouseEnd({ x: e.pageX, y: e.pageY })
            }
          }}
          onMouseUp={e => {
            if (!isSwiping) {
              if (e.target.className !== "close") {
                togglePlay()
              }
            }
            setClicking(false)
            setSwiping(false)
          }}
          onKeyUp={togglePlay}
          role="button"
          tabIndex="0"
        >
          <div className="h-full overflow-y-hidden">
            <div className="">
              <ReactPlayer
                url={props.trailer.file.url}
                playing={toPlay}
                width="100%"
                height="100%"
                light={props.trailerThumbnail.fluid.srcWebp}
                controls={false}
                playIcon={<img src={PlayButton} alt="play" />}
              />
            </div>
            <button
              onClick={closeTrailer}
              onKeyUp={closeTrailer}
              className="absolute top-0 right-0"
            >
              <img src={CloseIcon} className="close" alt="close" />
            </button>
            <div className="px-7 pt-7 space-y-7 ">
              <div className="flex flex-col lg:flex-row justify-between">
                <h5>{props.title}</h5>
                <span className="body-3 lg:pl-2">{props.releaseYear}</span>
              </div>
              <div>
                <span className="body-1">{props.description}</span>
              </div>
              <div></div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.externalLink}
                className="link absolute bottom-6 left-7"
              >
                More Info
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "featured-poster " + (showTrailer ? "absolute top-0 opacity-0" : "")
        }
        onKeyUp={() => openTrailer()}
        onMouseDown={e => {
          setSwiping(false)
          setClicking(true)
          setMouseStart({ x: e.pageX, y: e.pageY })
        }}
        onMouseMove={e => {
          if (isClicking) {
            setMouseEnd({ x: e.pageX, y: e.pageY })
          }
        }}
        onMouseUp={e => {
          if (!isSwiping) {
            if (e.target.className !== "close") {
              openTrailer()
            }
          }
          setClicking(false)
          setSwiping(false)
        }}
        role="button"
        tabIndex="0"
      >
        <div className="poster h-full ">
          <div
            onKeyUp={() => openTrailer()}
            className="h-full"
            role="button"
            tabIndex="0"
          >
            <Img fluid={props.poster.fluid} className="h-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedCarouselSlide